import React, { useState, useEffect, lazy, Suspense } from 'react';
import  businessGuid from './pages/BuinessGuid'
console.log(businessGuid)
const AppLazy = lazy(() => import('./App')); // Import App.js dynamically

function Landing() {
    const [appLoaded, setAppLoaded] = useState(false);

    useEffect(() => {
        // Simulate a delay before loading App.js
        const delay = setTimeout(() => {
            setAppLoaded(true);
        }, 2000); // Delay in milliseconds

        return () => {
            clearTimeout(delay);
        };
    }, []);

    return (
        <div className="App">
            <Suspense fallback={<p></p>}>
                {appLoaded ? (
                    <AppLazy /> // Render the dynamically imported App.js component
                ) : (
                    <p></p>
                )}
            </Suspense>
        </div>
    );
}

export default Landing;