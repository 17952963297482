import axios from "axios";
import helper from "./client-api/helper";
const COOKIE_NAME = 'pos-admin-frontend-deviceId';

class FirstCallApi {
    constructor(url, apiKey, secret, deviceId, token) {
        this.url = url;
        this.apiKey = apiKey;
        this.secret = secret;
        this.deviceId = deviceId ? deviceId : this._generateDeviceId();
        this._token = token;
        if(localStorage.length ===0){
            const headers = {
                'X-Device-Identifier': this.deviceId,
                'X-Api-Secret': this.secret,
                'X-Api-Key':this.apiKey,
                "X-Request-Id": "64e436ab99caf.QVivDa"
            };
            const Url = process.env.REACT_APP_SOLDI_POS3_URL+'/api/config/application'
             axios.get(Url, { headers })
                .then(response => {
                    localStorage.setItem('application_config', JSON.stringify(response.data.data));
                    return response.data
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    _generateDeviceId() {
        const cookie = helper.getCookie(COOKIE_NAME);
        if (cookie) return cookie;
        const cookieValue = this.uniqid(`${COOKIE_NAME}-${helper.randomString(20)}`);
        helper.setCookie(COOKIE_NAME, cookieValue, 30);
        return cookieValue;
    }

    uniqid(prefix, more_entropy) {
        if (typeof prefix === 'undefined') {
            prefix = '';
        }

    }

}

export default FirstCallApi